<script setup>
import Layout from "../../layouts/main.vue";
import { ref, reactive, onMounted } from "vue";

import { useRouter, useRoute } from "vue-router";
import Data from "./dataFormKPI";
import MethodService from "../../service/MethodService";
import mushroom from "cem-primary-api";
import Swal from "sweetalert2";
import toastr from "toastr";

// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
import { watch } from "vue";
import LanguageService from "../../service/LanguageService";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const tableRules = reactive(MethodService.copyObject(Data.tableRules));
const dataTables = reactive({ value: [] });

let loading = ref(false);
const pageSizeDefault = ref(10);
const listVisible = [
  {
    value: "true",
    get label() {
      return t("t-display");
    },
  },
  {
    value: "false",
    get label() {
      return t("t-hide");
    },
  },
];

const getListKPI = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router
    .replace({
      name: "ServiceHealth",
      query: {
        ...dataFilter,
      },
    })
    .catch(() => {});

  try {
    const response = await mushroom.service_health_kpi.listAsync(dataFilter, {
      cacheAge: 1,
    });
    console.log("Các quality_level thỏa mãn: %o, info: %o", response);
    if (response.result) {
      loading.value = false;
      // lengthProvince.value = response.result.length;
      // let res = await changeData(response.result);
      // dataTables.value = res;
      dataTables.value = response.result;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};

// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  getListKPI();
};
const fn_tableSortChange = (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  loading.value = true;
  getListKPI();
};

// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
};
// tìm kiếm
const fn_TimKiem = () => {
  tableRules.dataSearch.value["code"]
    ? (tableRules.dataSearch.value["code"] =
        tableRules.dataSearch.value["code"].trim())
    : (tableRules.dataSearch.value["code"] = "");

  tableRules.dataSearch.value["label"]
    ? (tableRules.dataSearch.value["label"] =
        tableRules.dataSearch.value["label"].trim())
    : (tableRules.dataSearch.value["label"] = "");

  tableRules.filters = MethodService.filterTable(
    JSON.stringify(tableRules.dataSearch)
  );
  tableRules.offset = 0;
  tableRules.sort = "";
  getListKPI();
};

const fn_Add = () => {
  router.push({ name: "ServiceHealthAdd" });
};

const fn_handle = async (type, scope, row) => {
  if (type == "view") {
    router.push({
      name: "ServiceHealthDetail",
      params: { id: row.id },
    });
  }
  if (type === "update") {
    router.push({
      name: "ServiceHealthUpdate",
      params: { id: row.id },
    });
  }
  if (type === "delete") {
    Swal.fire({
      title: t("t_warning"),
      text: t("t_service_health_kpi_delete"),
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: t("swal_accept"),
      cancelButtonText: t("swal_cancel"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseGroup = await mushroom.service_health_kpi.deleteAsync({
            id: row.id, // required
          });
          if (responseGroup.result) {
            toastr.success(
              LanguageService[lang.value]
                ?.toastr_delete_service_health_kpi_success ??
                "toastr_delete_service_health_kpi_success"
            );
            getListKPI();
          }
        } catch (e) {
          console.error("Có lỗi: %o", e.message);
        }
      }
    });
  }
};

const fn_hashUrl = () => {
  MethodService.hashUrl(route.query, tableRules);
  pageSizeDefault.value = tableRules.limit;
  if (route.query.offset && route.query.limit) {
    tableRules.offset = Number(route.query.offset);
    tableRules.page = Number(route.query.offset) / route.query.limit + 1;
  }
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
  }
);

onMounted(() => {
  fn_hashUrl();
  getListKPI();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">Service health kpi</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border me-2"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button class="btn btn-secondary btn-border" @click="fn_Add">
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="top"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="rulesForm"
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="4">
                      <el-form-item :label="$t('t-service-health-kpi-code')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['code']"
                          :placeholder="$t('t-place-service-health-kpi-code')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="4">
                      <el-form-item :label="$t('t-service-health-kpi-name')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['label']"
                          :placeholder="$t('t-place-service-health-kpi-name')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="4">
                      <el-form-item :label="$t('t-service-health-kpi-display')">
                        <el-select
                          v-model="tableRules.dataSearch.value['visible']"
                          filterable
                          clearable
                          :placeholder="
                            $t('t-select-service-health-kpi-display')
                          "
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                        >
                          <el-option
                            v-for="item in listVisible"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem()"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              background
              layout="total, sizes, prev, pager, next, jumper"
            />
          </div>
          <el-table
            size="small"
            style="width: 100%"
            :data="dataTables.value"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop=""
              :label="$t('t-stt')"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="code"
              :label="$t('t-service-health-kpi-code')"
              min-width="100"
              align="start"
            />
            <el-table-column
              prop="label"
              :label="$t('t-place-service-health-kpi-code')"
              min-width="100"
              align="start"
            />
            <el-table-column
              prop="index"
              :label="$t('t-service-health-kpi-index')"
              min-width="90"
              align="start"
            />
            <el-table-column
              prop="unit"
              :label="$t('t-service-health-kpi-unit')"
              min-width="80"
              align="start"
            />
            <el-table-column
              prop="link"
              :label="$t('t-link')"
              min-width="150"
              align="start"
            >
              <template #default="scope">
                <el-tooltip :content="scope.row.link" effect="light">
                  <span class="link-table">
                    {{ scope.row.link }}
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="visible"
              :label="$t('t-service-health-kpi-display')"
              min-width="80"
              align="start"
            >
              <template #default="scope">
                <span
                  v-if="scope.row.visible"
                  class="badge badge-soft-success text-uppercase"
                  >{{ $t("t-display") }}</span
                >
                <span v-else class="badge badge-soft-danger text-uppercase">{{
                  $t("t-hide")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('t-action')"
              align="center"
              width="150px"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    :content="$t('t-view')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-info waves-effect waves-light btn-sm"
                      @click="fn_handle('view', scope.$index, scope.row)"
                    >
                      <i class="ri-eye-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('t-edit')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.$index, scope.row)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('t-delete')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.$index, scope.row)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.el-table {
  height: calc(100vh - 236px);
}
.link-table {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
